import {combineReducers} from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import MyProfile from "./auth/myprofile/reducer"

import Community from "./community/reducer"


const rootReducer = combineReducers({
    // public
    Layout,

    Login,
    MyProfile,

    Community,
})

export default rootReducer
