import {COMMUNITY_APPROVE_ME, COMMUNITY_APPROVE_ME_SUCCESS} from "./actionTypes"

export const communityApproveMe = () => {
    return {
        type: COMMUNITY_APPROVE_ME,
        payload: {},
    }
}

export const communityApproveMeSuccess = myApprove => {
    return {
        type: COMMUNITY_APPROVE_ME_SUCCESS,
        payload: myApprove,
    }
}
