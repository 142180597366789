import React, {Component} from "react"

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container-fluid">
                        <a href="https://cs.reku.kr/privacy-policy"
                           target="_blank"
                           rel="noreferrer">개인정보 처리방침</a>
                        <p className="mb-0">
                            Copyright {new Date().getFullYear()}. Horyu All rights reserved.
                        </p>
                        <p style={{color: 'red', fontSize: '12px'}}>
                            그림의 저작권은 해당 그림의 저작권자에게 있으며, 저작권자의 허락없이 불법으로 복사/유포하는 행위를 엄격히 금지합니다. 이외에 사이트의 모든 저작권은 Horyu 에게 있습니다.
                        </p>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;
