import React, {Component} from "react"
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom"
import {UncontrolledTooltip} from "reactstrap";
import {v4} from "uuid";
import moment from "moment";
import "moment/locale/ko";

class TimeText extends Component {
    constructor(props) {
        super(props)

        const uuid = v4();
        this.state = {
            uniqueId: `time-text-${uuid}`,
            timeMoment: moment(this.props.time),
            realTimeTimerId: null,
            realTimeText: null
        }

        this.startUpdateRealTimeTextTimer = this.startUpdateRealTimeTextTimer.bind(this)
        this.updateRealTimeText = this.updateRealTimeText.bind(this)
    }

    componentDidMount() {
        this.startUpdateRealTimeTextTimer();
    }

    componentWillUnmount() {
        clearInterval(this.state.realTimeTimerId);
    }

    startUpdateRealTimeTextTimer() {
        const timerId = setInterval(this.updateRealTimeText, 0, 1000);
        this.setState({realTimeTimerId: timerId});
    }

    updateRealTimeText() {
        this.setState({
            realTimeText: this.state.timeMoment.fromNow()
        });
    }

    render() {
        return (
            <React.Fragment>
                <UncontrolledTooltip
                    innerClassName="date-tooltip"
                    placement={'top'}
                    target={this.state.uniqueId}
                >
                    {this.state.timeMoment.format('YYYY년 MM월 DD일 dddd')}
                    <br/>
                    {this.state.timeMoment.format('A hh시 mm분 ss초')}
                </UncontrolledTooltip>

                <span id={this.state.uniqueId}>
                    {this.state.realTimeText}
                </span>
            </React.Fragment>
        )
    }
}

TimeText.propTypes = {
    time: PropTypes.string.isRequired,
}

export default withRouter(TimeText)
