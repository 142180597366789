import React, {Component} from "react"
import {Alert, Modal, ModalBody, ModalHeader,} from "reactstrap";
import PropTypes from "prop-types";

class ApproveUnblockModal extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                scrollable={true}
                backdrop={'static'}
                id="staticBackdrop"
            >
                <ModalHeader toggle={this.props.onCancel}>
                    [#{this.props.approve ? this.props.approve.id : ''}] 입장 신청 차단 해제
                </ModalHeader>
                <ModalBody>
                    <Alert color="info" role="alert">
                        <b>아래 사용자의 레쿠 소통방 입장 신청 차단을 해제할까요?</b>
                        <br/><br/>
                        트위터 닉네임: {this.props.approve ? this.props.approve.twitterName : ''}
                        <br/>
                        트위터 아이디: @{this.props.approve ? this.props.approve.twitterUsername : ''}
                        <br/><br/>
                        <b>차단 해제 시 다시 레쿠 소통방 입장 신청을 보낼 수 있게 됩니다.</b>
                    </Alert>
                </ModalBody>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light"
                            onClick={() => this.props.onCancel()}>
                        취소
                    </button>
                    <button type="button" className="btn btn-info"
                            onClick={() => this.props.onSubmit('')}>
                        입장 신청 차단 해제
                    </button>
                </div>
            </Modal>
        )
    }
}

ApproveUnblockModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    approve: PropTypes.object,

    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default ApproveUnblockModal;
