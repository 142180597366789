import React, {Component} from "react"
import PropTypes from "prop-types";
import ApproveListFilterButton from "./ApproveListFilterButton";

import "./approveListFilter.scss";

class ApproveListFilter extends Component {
    constructor(props) {
        super(props);

        this.changeFilter = this.changeFilter.bind(this)
        this.selectAllFilters = this.selectAllFilters.bind(this)
        this.deSelectAllFilters = this.deSelectAllFilters.bind(this)
        this.changeAllFilterTo = this.changeAllFilterTo.bind(this)
    }

    changeFilter(filterKey, isChecked) {
        const newState = {
            currentFilters: {
                ...this.props.currentFilters,
                [filterKey]: isChecked
            }
        };

        this.props.onChange(newState.currentFilters)
    }

    selectAllFilters() {
        this.changeAllFilterTo(true);
    }

    deSelectAllFilters() {
        this.changeAllFilterTo(false);
    }

    changeAllFilterTo(isChecked) {
        const newState = {
            currentFilters: {
                NEW_APPROVE: isChecked,
                ACCEPT: isChecked,
                REMOVE_ACCEPT: isChecked,
                REJECT: isChecked,
                BLOCK: isChecked,
                UNBLOCK: isChecked,
            }
        };

        this.props.onChange(newState.currentFilters)
    }

    render() {
        return (
            <div className="list-status-filter">
                <div className="text-col">
                    목록 상태 필터
                </div>
                <div className="filter-col">
                    <div className="btn-group" role="group">
                        <ApproveListFilterButton
                            filterKey="NEW_APPROVE"
                            filterName={`심사 대기 (${this.props.countMap.NEW_APPROVE})`}
                            filterColor="primary"

                            checked={this.props.currentFilters.NEW_APPROVE}
                            onChange={isChecked => this.changeFilter('NEW_APPROVE', isChecked)}
                        />

                        <ApproveListFilterButton
                            filterKey="ACCEPT"
                            filterName={`입장 허가 (${this.props.countMap.ACCEPT})`}
                            filterColor="success"

                            checked={this.props.currentFilters.ACCEPT}
                            onChange={isChecked => this.changeFilter('ACCEPT', isChecked)}
                        />

                        <ApproveListFilterButton
                            filterKey="REMOVE_ACCEPT"
                            filterName={`입장 허가 취소 (${this.props.countMap.REMOVE_ACCEPT})`}
                            filterColor="secondary"

                            checked={this.props.currentFilters.REMOVE_ACCEPT}
                            onChange={isChecked => this.changeFilter('REMOVE_ACCEPT', isChecked)}
                        />

                        <ApproveListFilterButton
                            filterKey="REJECT"
                            filterName={`입장 거절 (${this.props.countMap.REJECT})`}
                            filterColor="warning"

                            checked={this.props.currentFilters.REJECT}
                            onChange={isChecked => this.changeFilter('REJECT', isChecked)}
                        />

                        <ApproveListFilterButton
                            filterKey="BLOCK"
                            filterName={`입장 요청 차단 (${this.props.countMap.BLOCK})`}
                            filterColor="danger"

                            checked={this.props.currentFilters.BLOCK}
                            onChange={isChecked => this.changeFilter('BLOCK', isChecked)}
                        />

                        <ApproveListFilterButton
                            filterKey="UNBLOCK"
                            filterName={`입장 요청 차단 해제 (${this.props.countMap.UNBLOCK})`}
                            filterColor="secondary"

                            checked={this.props.currentFilters.UNBLOCK}
                            onChange={isChecked => this.changeFilter('UNBLOCK', isChecked)}
                        />
                    </div>

                    <div className="filter-utility">
                        <button type="button" className="btn btn-outline-primary btn-sm"
                                onClick={this.selectAllFilters}>
                            모든 필터 켜기
                        </button>
                        <button type="button" className="btn btn-outline-primary btn-sm"
                                style={{marginLeft: '5px'}}
                                onClick={this.deSelectAllFilters}>
                            모든 필터 끄기
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

ApproveListFilter.propTypes = {
    currentFilters: PropTypes.object.isRequired,
    countMap: PropTypes.object.isRequired,

    onChange: PropTypes.func.isRequired,
};

export default ApproveListFilter;
