import React, {Component} from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Col} from "reactstrap"
import bannerImage from "../../assets/images/banner.jpg";

export default class CarouselPage extends Component {
    render() {
        return (
            <React.Fragment>
                <Col xl={9}>
                    <div className="auth-full-bg pt-lg-5 p-4">
                        <div className="w-100">
                            <div className="bg-overlay" style={{background: `url(${bannerImage}) no-repeat center`}}/>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        )
    }
}
