import {call, put, takeLatest} from "redux-saga/effects"

// Login Redux States
import {MY_PROFILE_FETCH} from "./actionTypes"
import {
    fetchingMyProfile,
    receivedMyProfileAndLoggedIn,
    receivedMyProfileAndNotLoggedIn,
    requestFailedMyProfile
} from "./actions";

//Include Both Helper File with needed methods
import {getMyProfile} from "../../../helpers/backend_helper";

function* listenForFetchMyProfile() {
    yield put(fetchingMyProfile())

    try {
        const myProfileResult = yield call(getMyProfile)

        if (myProfileResult.resultCode === 'SUCCESS') {
            yield put(receivedMyProfileAndLoggedIn(myProfileResult.data))
        } else {
            yield put(receivedMyProfileAndNotLoggedIn())
        }
    } catch {
        yield put(requestFailedMyProfile())
    }
}

function* myProfileSaga() {
    yield takeLatest(MY_PROFILE_FETCH, listenForFetchMyProfile)
}

export default myProfileSaga
