import React, {Component} from "react"
import PropTypes from "prop-types";

//i18n
import {withTranslation} from "react-i18next"

class TwitterProfileLink extends Component {
    render() {
        return (
            <a
                href={`https://twitter.com/${this.props.twitterUsername}`}
                target="_blank"
                rel="noreferrer"
            >
                @{this.props.twitterUsername}
            </a>
        )
    }
}

TwitterProfileLink.propTypes = {
    twitterUsername: PropTypes.string.isRequired,
}

export default withTranslation()(TwitterProfileLink)
