import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";
import {communityApproveMe} from "../../store/community/actions";

//i18n

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.refDiv = React.createRef();
        this.updateApproval = this.updateApproval.bind(this)
    }

    componentDidMount() {
        this.initMenu();
        this.updateApproval();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    updateApproval() {
        this.props.communityApproveMe();
    }

    initMenu() {
        new MetisMenu("#side-menu");

        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    // componentDidUpdate() {}

    scrollElement = item => {
        setTimeout(() => {
            if (this.refDiv.current !== null) {
                if (item) {
                    const currentPosition = item.offsetTop;
                    if (currentPosition > window.innerHeight) {
                        if (this.refDiv.current)
                            this.refDiv.current.getScrollElement().scrollTop =
                                currentPosition - 300;
                    }
                }
            }
        }, 300);
    };

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            this.scrollElement(item);
            return false;
        }
        this.scrollElement(item);
        return false;
    };

    render() {
        const user = this.props.myProfile;
        const myApprove = this.props.myApprove;

        const isAcceptedApprove = myApprove && ('ACCEPTED' === myApprove.kakaoTalk.status || 'ACCEPTED' === myApprove.discord.status);

        return (
            <React.Fragment>
                <SimpleBar className="h-100" ref={this.refDiv}>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">레쿠 소통방</li>
                            {isAcceptedApprove && <>
                                <li>
                                    <Link to="/community/staff" className="">
                                        <i className="bx bx-coffee"/>
                                        <span>관리자 목록</span>
                                    </Link>
                                </li>
                            </>}
                            <li>
                                <Link to="/community/approve" className="">
                                    <i className="bx bx-message-add"/>
                                    <span>입장</span>
                                </Link>
                            </li>

                            {/*{user.authorities.includes('MANAGE_COMMUNITY_ACCESS') && <li>*/}
                            {/*    <Link to="/community/approve/list" className="">*/}
                            {/*        <i className="bx bx-cog"/>*/}
                            {/*        <span>입장 신청 관리</span>*/}
                            {/*    </Link>*/}
                            {/*</li>}*/}

                            {/*<li className="menu-title">캐릭터</li>*/}
                            {/*<li>*/}
                            {/*  <Link to="/character/list" className="">*/}
                            {/*    <i className="bx bx-list-ul" />*/}
                            {/*    <span>공개 캐릭터 목록</span>*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*  <Link to="/character/manage" className="">*/}
                            {/*    <i className="bx bx-cog" />*/}
                            {/*    <span>내 캐릭터 관리</span>*/}
                            {/*  </Link>*/}
                            {/*</li>*/}

                            {/*<li className="menu-title">단체 커미션</li>*/}
                            {/*<li>*/}
                            {/*  <Link to="/commission/list" className="">*/}
                            {/*    <i className="bx bx-list-ul" />*/}
                            {/*    <span>단체 커미션 목록</span>*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*  <Link to="/commission/new" className="">*/}
                            {/*    <i className="bx bx-pencil" />*/}
                            {/*    <span>단체 커미션 등록</span>*/}
                            {/*  </Link>*/}
                            {/*</li>*/}

                            {(user.authorities.includes('MANAGE_COMMUNITY_ACCESS') || user.authorities.includes('MANAGE_USER')) && <>
                                <li className="menu-title">관리자</li>
                            </>}

                            {user.authorities.includes('MANAGE_COMMUNITY_ACCESS') && <React.Fragment>
                                <li>
                                    <Link to="/admin/community/approve" className="">
                                        <i className="bx bx-cog"/>
                                        <span>입장 신청 관리</span>
                                    </Link>
                                </li>
                            </React.Fragment>}
                            {user.authorities.includes('MANAGE_USER') && <React.Fragment>
                                <li>
                                    <Link to="/admin/user" className="">
                                        <i className="bx bx-list-ul"/>
                                        <span>사용자 관리</span>
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*  <Link to="/admin/user/block" className="">*/}
                                {/*    <i className="bx bx-list-ul" />*/}
                                {/*    <span>사용자 차단 관리</span>*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                            </React.Fragment>}

                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        );
    }
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,

    myProfile: PropTypes.object,
    myApprove: PropTypes.object,

    communityApproveMe: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    myProfile: state.MyProfile.myProfile,
    myApprove: state.Community.myApprove,
})

export default withRouter(
    connect(mapStateToProps, {communityApproveMe})(SidebarContent)
);
