import React, {Component} from "react"
import PropTypes from 'prop-types';
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import {logoutUser} from "../../store/actions"
import {getLogout} from "../../helpers/backend_helper";

class Logout extends Component {
    componentDidMount = () => {
        getLogout().then(() => {
            location.reload();
        })
    }

    render() {
        return <React.Fragment></React.Fragment>
    }
}

Logout.propTypes = {
    logoutUser: PropTypes.func
}

export default withRouter(connect(null, {logoutUser})(Logout))
