import React, {Component} from "react"
import PropTypes from "prop-types";
import classnames from "classnames";
import {Card, CardBody, CardTitle, Col, Container, Input, Label, Row,} from "reactstrap";
import {putCommunityApprove} from "../../helpers/backend_helper";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {communityApproveMe} from "../../store/community/actions";
import moment from "moment";
import "moment/locale/ko";

import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import StatusAlert from "./StatusAlert";

import "./communityApprove.scss";
import toastr from "toastr";

class CommunityApprove extends Component {
    constructor(props) {
        super(props);

        this.sendApply = this.sendApply.bind(this)
        this.updateApproval = this.updateApproval.bind(this)
        this.refreshStatus = this.refreshStatus.bind(this)
    }

    componentDidMount() {
        toastr.options = {
            positionClass: 'toast-top-full-width',
            timeOut: 5000,
            closeButton: true,
            progressBar: true,
        };

        this.updateApproval();
    }

    sendApply(data) {
        putCommunityApprove(data).then(res => {
            if (res.resultCode === 'SUCCESS') {
                toastr.success('작성하신 입장 신청서가 성공적으로 접수되었습니다.');
                this.updateApproval();
            } else {
                toastr.error(res.resultMessage, '문제 발생');
            }
        }).catch(() => {
            toastr.error('서버와 통신 중 문제가 발생했습니다. 잠시 후 다시 시도해주세요.');
        })
    }

    updateApproval() {
        this.props.communityApproveMe();
    }

    refreshStatus() {
        this.props.communityApproveMe();
        toastr.success('입장 상태를 업데이트했습니다.');
    }

    render() {
        document.title = '입장 / 레쿠 소통방';

        const myProfile = this.props.myProfile;
        const myApprove = this.props.myApprove;

        const ableApplyStatus = ['NONE', 'REJECTED'];
        const isAbleApplyKakaoTalk = myApprove && ableApplyStatus.includes(myApprove.kakaoTalk.status)
        const isAbleApplyDiscord = myApprove && ableApplyStatus.includes(myApprove.discord.status)
        const isAbleApply = isAbleApplyKakaoTalk || isAbleApplyDiscord

        return (
            <React.Fragment>
                <div className="page-content approve-new-page">
                    <Container fluid>
                        <Breadcrumbs title="레쿠 소통방" breadcrumbItem="입장"/>

                        <Card>
                            <CardBody>
                                <CardTitle className="mb-3">
                                    입장 상태 확인 <button type="button" className="btn btn-sm btn-success"
                                                     style={{marginLeft: '10px'}}
                                                     onClick={this.refreshStatus}>새로고침</button>
                                </CardTitle>
                                {myApprove ? <>
                                    <StatusAlert community="kakaoTalk" myApprove={myApprove}/>
                                    <StatusAlert community="discord" myApprove={myApprove}/>
                                    <StatusAlert community="telegram" myApprove={myApprove}/>
                                </> : <h4>데이터 불러오는 중...</h4>}
                            </CardBody>
                        </Card>

                        {isAbleApply && <React.Fragment>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-3">
                                        입장 신청서 작성
                                    </CardTitle>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            termCheck: false,
                                            contents: '',
                                            requestKakaoTalk: false,
                                            requestDiscord: false,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            termCheck: Yup.boolean().oneOf([true], "안내 사항에 동의해주세요."),
                                            requestKakaoTalk: Yup.boolean(),
                                            requestDiscord: Yup.boolean(),
                                        }).test(
                                            'requestCommunityTester',
                                            null,
                                            (obj) => {
                                                if (obj.requestKakaoTalk || obj.requestDiscord) return true;

                                                return new Yup.ValidationError(
                                                    '최소 1개의 커뮤니티를 선택해주세요.',
                                                    null,
                                                    'requestCommunity'
                                                );
                                            }
                                        )}
                                        onSubmit={values => {
                                            this.sendApply({
                                                contents: values.contents,
                                                requestKakaoTalk: values.requestKakaoTalk,
                                                requestDiscord: values.requestDiscord,
                                            });
                                        }}
                                    >
                                        {({errors, status, touched}) => (
                                            <Form className="approve-new-form">
                                                <div>
                                                    <Label htmlFor="contents">안내</Label>
                                                    <Input
                                                        type="textarea"
                                                        className="form-control notice-textarea"
                                                        readOnly
                                                        value="입장 신청서 제출 시 사용자분께서 이전에 문제가 있는 분인지 판단하기 위해 저희 입장 관리팀이 사용자분의 트위터 계정을 살펴볼 수 있습니다."
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <div className="form-check term-check">
                                                        <Field
                                                            type="checkbox"
                                                            className={"form-check-Input form-check-input " + (errors.termCheck && touched.termCheck
                                                                ? "is-invalid"
                                                                : "")}
                                                            name="termCheck"
                                                            id="termCheck"
                                                        />
                                                        <Label
                                                            className={"form-check-Label " + (errors.termCheck && touched.termCheck
                                                                ? "red-label"
                                                                : "")}
                                                            htmlFor="termCheck"
                                                        >
                                                            상기 안내를 확인하였으며, 이에 동의합니다.
                                                        </Label>
                                                        <ErrorMessage
                                                            name="termCheck"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                </div>

                                                <Row className="mb-3">
                                                    <Col md={4}>
                                                        <Label htmlFor="formrow-email-Input">트위터
                                                            닉네임</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={myProfile.twitterName}
                                                            readOnly
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Label htmlFor="formrow-password-Input">트위터
                                                            아이디</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={`@${myProfile.twitterUsername}`}
                                                            readOnly
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Label htmlFor="formrow-password-Input">트위터
                                                            가입일</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            value={moment(myProfile.twitterCreatedAt).format('LLLL')}
                                                            readOnly
                                                        />
                                                    </Col>
                                                </Row>

                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-password-Input">입장을 신청할
                                                        커뮤니티</Label>
                                                    <div className="form-check">
                                                        <Field
                                                            type="checkbox"
                                                            className={classnames('form-check-input', {
                                                                'is-invalid': errors.requestCommunity
                                                            })}
                                                            name="requestKakaoTalk"
                                                            id="requestKakaoTalk"
                                                            disabled={!isAbleApplyKakaoTalk}
                                                        />
                                                        <label
                                                            className={classnames('form-check-label', {
                                                                'red-label': errors.requestCommunity
                                                            })}
                                                            htmlFor="requestKakaoTalk"
                                                        >
                                                            카카오톡 (KakaoTalk) & 텔레그램 (Telegram)
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <Field
                                                            type="checkbox"
                                                            className={classnames('form-check-input', {
                                                                'is-invalid': errors.requestCommunity
                                                            })}
                                                            name="requestDiscord"
                                                            id="requestDiscord"
                                                            disabled={!isAbleApplyDiscord}
                                                        />
                                                        <label
                                                            className={classnames('form-check-label', {
                                                                'red-label': errors.requestCommunity
                                                            })}
                                                            htmlFor="requestDiscord"
                                                        >
                                                            디스코드 (Discord)
                                                        </label>
                                                    </div>
                                                    {errors.requestCommunity &&
                                                        <div className="invalid-feedback" style={{display: 'block'}}>
                                                            {errors.requestCommunity}
                                                        </div>}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="contents">입장 관리팀에 전하고 싶은 내용 (최대 200자)</Label>
                                                    <Field
                                                        as="textarea"
                                                        type="textarea"
                                                        className="form-control"
                                                        rows={5}
                                                        maxLength={200}
                                                        name="contents"
                                                        id="contents"
                                                        placeholder="입장 관리팀에 추가적으로 전달하고 싶으신 말이 있으시다면 여기에 입력해주세요."
                                                    />
                                                </div>

                                                <div>
                                                    <button type="submit" className="btn btn-primary w-md">
                                                        전송
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </React.Fragment>}
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

CommunityApprove.propTypes = {
    myProfile: PropTypes.object,
    myApprove: PropTypes.object,

    communityApproveMe: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    myProfile: state.MyProfile.myProfile,
    myApprove: state.Community.myApprove,
})

export default withRouter(
    connect(mapStateToProps, {communityApproveMe})(CommunityApprove)
);
