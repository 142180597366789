import React, {Component} from "react"
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import {getCommunityJoin} from "../../helpers/backend_helper";
import toastr from "toastr";

class StatusAlert extends Component {
    constructor(props) {
        super(props);

        toastr.options = {
            positionClass: 'toast-top-full-width',
            timeOut: 5000,
            closeButton: true,
            progressBar: true,
        };

        this.getCommunityBgColor = this.getCommunityBgColor.bind(this)
        this.getCommunityColor = this.getCommunityColor.bind(this)
        this.getCommunityName = this.getCommunityName.bind(this)
        this.getCommunityServerType = this.getCommunityServerType.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    getCommunityBgColor() {
        const community = this.props.community;
        if (community === 'kakaoTalk') return '#FEE500';
        else if (community === 'discord') return '#5865F2';
        else if (community === 'telegram') return '#2AABEE';
        return '';
    }

    getCommunityColor() {
        const community = this.props.community;
        if (community === 'kakaoTalk') return 'black';
        else if (community === 'discord') return 'white';
        else if (community === 'telegram') return 'white';
        return '';
    }

    getCommunityName() {
        const community = this.props.community;
        if (community === 'kakaoTalk') return '카카오톡 (KakaoTalk)';
        else if (community === 'discord') return '디스코드 (Discord)';
        else if (community === 'telegram') return '텔레그램 (Telegram)';
        return '오류';
    }

    getCommunityServerType() {
        const community = this.props.community;
        if (community === 'kakaoTalk') return 'KAKAO_TALK';
        else if (community === 'discord') return 'DISCORD';
        else if (community === 'telegram') return 'TELEGRAM';
        return 'ERROR';
    }

    handleClick() {
        getCommunityJoin(this.getCommunityServerType()).then(res => {
            const resultCode = res.resultCode;
            const resultMessage = res.resultMessage;

            if (resultCode !== 'SUCCESS') {
                toastr.error(resultMessage, '문제 발생');
                return;
            }

            window.open(res.data, '_blank');
        })
    }

    render() {
        const community = this.props.community;

        const status = this.props.myApprove[community].status;
        const statusMessage = this.props.myApprove[community].statusMessage;

        return (
            <Alert role="alert" style={{
                backgroundColor: this.getCommunityBgColor(),
                color: this.getCommunityColor(),
            }}>
                <h5 className="alert-heading">
                    {this.getCommunityName()}
                </h5>

                {status === 'NONE' && <>
                    아래에서 입장 신청서를 작성하실 수 있습니다.
                </>}

                {status === 'UNDER_REVIEW' && <>
                    입장 신청서를 작성해주셔서 감사합니다.
                    <br/><br/>
                    현재 입장 관리팀에서 심사를 진행 중에 있습니다.
                    <br/><br/>
                    일정 시간 후 다시 방문부탁드립니다.
                </>}

                {status === 'ACCEPTED' && <>
                    입장이 허가되었습니다.
                    <br/><br/>
                    아래에서 커뮤니티에 참여하실 수 있습니다.
                    <br/><br/>
                    <button type="button" className="btn btn-light" onClick={this.handleClick}>
                        참여하기
                    </button>
                </>}

                {status === 'REJECTED' && <>
                    귀하의 입장 신청이 반려되었습니다.
                    {statusMessage && <>
                        <br/><br/>
                        사유: {statusMessage}
                    </>}
                    <br/><br/>
                    내용을 보완하여 다시 신청하실 수 있습니다.
                </>}

                {status === 'BLOCKED' && <>
                    입장 신청이 차단된 상태입니다.
                    {statusMessage && <>
                        <br/><br/>
                        사유: {statusMessage}
                    </>}
                </>}
            </Alert>
        )
    }
}

StatusAlert.propTypes = {
    community: PropTypes.string.isRequired,
    myApprove: PropTypes.shape({
        status: PropTypes.string,
        statusMessage: PropTypes.string
    })
}

export default StatusAlert;
