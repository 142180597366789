import {COMMUNITY_APPROVE_ME_SUCCESS} from "./actionTypes";

const initialState = {
    myApprove: null,
}

const community = (state = initialState, action) => {
    switch (action.type) {
        case COMMUNITY_APPROVE_ME_SUCCESS:
            return {
                ...state,
                myApprove: action.payload,
            }
        default:
            return {
                ...state
            }
    }
}

export default community
