import {call, put, takeEvery, takeLatest} from "redux-saga/effects"

// Login Redux States
import {LOGOUT_USER, TWITTER_LOGIN} from "./actionTypes"
import {loginFail, loginProcessing, loginSuccess} from "./actions"
import {communityApproveMe} from "../../community/actions";
import {fetchMyProfile} from "../myprofile/actions";

//Include Both Helper File with needed methods
import {getTwitterLogin} from "../../../helpers/backend_helper";

function* logoutUser({payload: {history}}) {
    history.push("/login")
}

function* twitterLogin({payload: {data, history}}) {
    yield put(loginProcessing())

    const loginResult = yield call(getTwitterLogin, data)
    if (loginResult.resultCode === 'SUCCESS') {
        yield put(loginSuccess())
        yield put(communityApproveMe());
        yield put(fetchMyProfile());

        history.push("/community/approve")
    } else {
        yield put(loginFail())
    }
}

function* authSaga() {
    yield takeLatest(TWITTER_LOGIN, twitterLogin)
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
