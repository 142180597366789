import React, {Component} from "react"
import {Button, Card, CardBody, Col, Container, Row, Table,} from "reactstrap";
import {getAdminCommunityApproveList, postAdminCommunityApproveAction} from "../../../helpers/backend_helper";
import TwitterProfileLink from "../../../components/TwitterProfileLink";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import ApproveListFilter from "./ApproveListFilter";
import ApproveAcceptModal from "./modal/ApproveAcceptModal";
import ApproveRejectModal from "./modal/ApproveRejectModal";
import ApproveBlockModal from "./modal/ApproveBlockModal";
import ApproveUnblockModal from "./modal/ApproveUnblockModal";
import ApproveRemoveAcceptModal from "./modal/ApproveRemoveAcceptModal";

import "./approveList.scss";
import ApproveLogModal from "./modal/ApproveLogModal";

class ApproveList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approveStateFilter: {
                NEW_APPROVE: true,
                ACCEPT: true,
                REMOVE_ACCEPT: true,
                REJECT: true,
                BLOCK: true,
                UNBLOCK: true,
            },
            approveList: [],
            modal: {
                approve: null,
                accept: false,
                remove_accept: false,
                reject: false,
                block: false,
                unblock: false,
                log: false
            }
        };

        this.updateTable = this.updateTable.bind(this)
        this.refreshStatus = this.refreshStatus.bind(this)
        this.handleActionSubmit = this.handleActionSubmit.bind(this)
        this.getStatusName = this.getStatusName.bind(this)
        this.getStatusColor = this.getStatusColor.bind(this)
        this.getStatusCountMap = this.getStatusCountMap.bind(this)
        this.getCommunityName = this.getCommunityName.bind(this)
    }

    componentDidMount() {
        this.updateTable();

        toastr.options = {
            positionClass: 'toast-top-full-width',
            timeOut: 5000,
            closeButton: true,
            progressBar: true,
        };
    }

    updateTable() {
        getAdminCommunityApproveList().then(res => {
            this.setState({approveList: res.data})
        })
    }

    refreshStatus() {
        this.updateTable();
        toastr.success('입장 신청 목록을 업데이트했습니다.');
    }

    handleActionSubmit(action, reason) {
        this.setState({
            modal: {
                ...this.state.modal,
                accept: false,
                remove_accept: false,
                reject: false,
                block: false,
                unblock: false
            }
        })

        postAdminCommunityApproveAction({
            approveId: this.state.modal.approve.id,
            action: action.toUpperCase(),
            message: reason,
        }).then(data => {
            if (data.resultCode === 'SUCCESS') {
                toastr.success(`${this.state.modal.approve.twitterName} 님에 대한 ${action} 작업을 완료했습니다.`, '작업 성공');
                this.updateTable()
            } else {
                toastr.error(`[${data.resultCode}] ${data.resultMessage}`, '작업 오류');
            }
        })
    }

    getStatusName(status) {
        if (status === 'NEW_APPROVE') {
            return '심사 대기';
        } else if (status === 'ACCEPT') {
            return '입장 허가';
        } else if (status === 'REMOVE_ACCEPT') {
            return '입장 허가 취소';
        } else if (status === 'REJECT') {
            return '입장 거절';
        } else if (status === 'BLOCK') {
            return '입장 요청 차단';
        } else if (status === 'UNBLOCK') {
            return '입장 요청 차단 해제';
        }

        return '오류';
    }

    getStatusColor(status) {
        if (status === 'NEW_APPROVE') {
            return '';
        } else if (status === 'ACCEPT') {
            return 'table-success';
        } else if (status === 'REMOVE_ACCEPT') {
            return 'table-light';
        } else if (status === 'REJECT') {
            return 'table-warning';
        } else if (status === 'BLOCK') {
            return 'table-danger';
        } else if (status === 'UNBLOCK') {
            return 'table-light';
        }

        return '';
    }

    getStatusCountMap() {
        const statusCountMap = {
            NEW_APPROVE: 0,
            ACCEPT: 0,
            REMOVE_ACCEPT: 0,
            REJECT: 0,
            BLOCK: 0,
            UNBLOCK: 0,
        };

        this.state.approveList.forEach(approve => {
            if (approve.status === 'NEW_APPROVE') statusCountMap.NEW_APPROVE++;
            if (approve.status === 'ACCEPT') statusCountMap.ACCEPT++;
            if (approve.status === 'REMOVE_ACCEPT') statusCountMap.REMOVE_ACCEPT++;
            if (approve.status === 'REJECT') statusCountMap.REJECT++;
            if (approve.status === 'BLOCK') statusCountMap.BLOCK++;
            if (approve.status === 'UNBLOCK') statusCountMap.UNBLOCK++;
        })

        return statusCountMap;
    }

    getCommunityName(community) {
        if (community === 'KAKAO_TALK') {
            return '카카오톡 (KakaoTalk)';
        } else if (community === 'DISCORD') {
            return '디스코드 (Discord)';
        }

        return '오류';
    }

    render() {
        document.title = '입장 신청 관리 / 관리자';

        return (
            <React.Fragment>
                <div className="page-content approve-list-page">
                    <Container fluid>
                        <Breadcrumbs title="관리자" breadcrumbItem="입장 신청 관리"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <ApproveListFilter
                                            currentFilters={this.state.approveStateFilter}
                                            countMap={this.getStatusCountMap()}

                                            onChange={filters => {
                                                this.setState({approveStateFilter: filters})
                                            }}
                                        />

                                        <button type="button" className="btn btn-sm btn-success mt-2"
                                                onClick={this.refreshStatus}>
                                            목록 새로고침
                                        </button>

                                        <div className="table-responsive">
                                            <Table className="table table-sm table-hover mb-0">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>상태</th>
                                                    <th>트위터</th>
                                                    <th>신청 커뮤니티</th>
                                                    <th>입장 관리팀에 전하는 말</th>
                                                    <th>마지막 처리자</th>
                                                    <th>사유/처리자 메모</th>
                                                    <th>작업</th>
                                                    <th>기록</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.approveList
                                                        .filter(approve => this.state.approveStateFilter[approve.status])
                                                        .map(approve =>
                                                            <tr key={approve.id}
                                                                className={this.getStatusColor(approve.status)}>
                                                                <th scope="row">{approve.id}</th>
                                                                <td>{this.getStatusName(approve.status)}</td>
                                                                <td>
                                                                    {approve.twitterName} (<TwitterProfileLink
                                                                    twitterUsername={approve.twitterUsername}/>)
                                                                </td>
                                                                <td>{this.getCommunityName(approve.community)}</td>
                                                                <td>{approve.contents}</td>
                                                                <td>{approve.adminNickname}</td>
                                                                <td>{approve.reason}</td>
                                                                <td>
                                                                    {approve.status === 'NEW_APPROVE' && <>
                                                                        <Button color="success" className="btn-sm"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modal: {
                                                                                            ...this.state.modal,
                                                                                            approve: approve,
                                                                                            accept: true
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                            허가
                                                                        </Button>
                                                                        <Button color="warning" className="btn-sm ms-1"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modal: {
                                                                                            ...this.state.modal,
                                                                                            approve: approve,
                                                                                            reject: true
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                            거절
                                                                        </Button>
                                                                        <Button color="danger" className="btn-sm ms-1"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modal: {
                                                                                            ...this.state.modal,
                                                                                            approve: approve,
                                                                                            block: true
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                            차단
                                                                        </Button>
                                                                    </>}
                                                                    {approve.status === 'ACCEPT' && <>
                                                                        <Button color="warning" className="btn-sm"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modal: {
                                                                                            ...this.state.modal,
                                                                                            approve: approve,
                                                                                            remove_accept: true
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                            허가 제거
                                                                        </Button>
                                                                    </>}
                                                                    {approve.status === 'BLOCK' && <>
                                                                        <Button color="danger" className="btn-sm"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modal: {
                                                                                            ...this.state.modal,
                                                                                            approve: approve,
                                                                                            unblock: true
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                            차단 해제
                                                                        </Button>
                                                                    </>}
                                                                </td>
                                                                <td>
                                                                    <Button color="primary" className="btn-sm"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    modal: {
                                                                                        ...this.state.modal,
                                                                                        approve: approve,
                                                                                        log: true
                                                                                    }
                                                                                })
                                                                            }}>
                                                                        기록
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                                </tbody>
                                            </Table>
                                        </div>

                                        <ApproveAcceptModal
                                            isOpen={this.state.modal.accept}
                                            approve={this.state.modal.approve}

                                            onCancel={() => this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    accept: false
                                                }
                                            })}
                                            onSubmit={reason => this.handleActionSubmit('accept', reason)}
                                        />

                                        <ApproveRemoveAcceptModal
                                            isOpen={this.state.modal.remove_accept}
                                            approve={this.state.modal.approve}

                                            onCancel={() => this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    remove_accept: false
                                                }
                                            })}
                                            onSubmit={reason => this.handleActionSubmit('remove_accept', reason)}
                                        />

                                        <ApproveRejectModal
                                            isOpen={this.state.modal.reject}
                                            approve={this.state.modal.approve}

                                            onCancel={() => this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    reject: false
                                                }
                                            })}
                                            onSubmit={reason => this.handleActionSubmit('reject', reason)}
                                        />

                                        <ApproveBlockModal
                                            isOpen={this.state.modal.block}
                                            approve={this.state.modal.approve}

                                            onCancel={() => this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    block: false
                                                }
                                            })}
                                            onSubmit={reason => this.handleActionSubmit('block', reason)}
                                        />

                                        <ApproveUnblockModal
                                            isOpen={this.state.modal.unblock}
                                            approve={this.state.modal.approve}

                                            onCancel={() => this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    unblock: false
                                                }
                                            })}
                                            onSubmit={reason => this.handleActionSubmit('unblock', reason)}
                                        />

                                        <ApproveLogModal
                                            isOpen={this.state.modal.log}
                                            approve={this.state.modal.approve}

                                            onClose={() => this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    log: false
                                                }
                                            })}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default ApproveList;
