import React, {Component} from "react"
import {Alert, Modal, ModalBody, ModalHeader,} from "reactstrap";
import PropTypes from "prop-types";

class ApproveAcceptModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
        };
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                scrollable={true}
                backdrop={'static'}
                id="staticBackdrop"
            >
                <ModalHeader toggle={this.props.onCancel}>
                    [#{this.props.approve ? this.props.approve.id : ''}] 입장 신청 수락
                </ModalHeader>
                <ModalBody>
                    <Alert color="success" role="alert">
                        <b>아래 사용자에게 레쿠 소통방 입장을 허가할까요?</b>
                        <br/><br/>
                        트위터 닉네임: {this.props.approve ? this.props.approve.twitterName : ''}
                        <br/>
                        트위터 아이디: @{this.props.approve ? this.props.approve.twitterUsername : ''}
                    </Alert>

                    <div>
                        <label htmlFor="reason" className="form-label">사유</label>
                        <input
                            type="text"
                            className="form-control"
                            id="reason"
                            placeholder="이 작업에 대해 기록할 내용이 있다면 적어주세요. (관리자에게만 보입니다)"
                            value={this.state.reason}
                            onChange={e => this.setState({reason: e.target.value})}
                        />
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light"
                            onClick={() => this.props.onCancel()}>
                        취소
                    </button>
                    <button type="button" className="btn btn-success"
                            onClick={() => this.props.onSubmit(this.state.reason)}>
                        입장 신청 수락
                    </button>
                </div>
            </Modal>
        )
    }
}

ApproveAcceptModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    approve: PropTypes.object,

    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default ApproveAcceptModal;
