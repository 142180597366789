import {
    MY_PROFILE_FETCH,
    MY_PROFILE_FETCHING,
    MY_PROFILE_RECEIVED_AND_LOGGED_IN,
    MY_PROFILE_RECEIVED_AND_NOT_LOGGED_IN,
    MY_PROFILE_REQUEST_FAILED
} from "./actionTypes"

export const fetchMyProfile = () => {
    return {
        type: MY_PROFILE_FETCH,
        payload: {},
    }
}

export const fetchingMyProfile = () => {
    return {
        type: MY_PROFILE_FETCHING,
        payload: {},
    }
}

export const receivedMyProfileAndLoggedIn = profile => {
    return {
        type: MY_PROFILE_RECEIVED_AND_LOGGED_IN,
        payload: profile,
    }
}

export const receivedMyProfileAndNotLoggedIn = () => {
    return {
        type: MY_PROFILE_RECEIVED_AND_NOT_LOGGED_IN,
        payload: {},
    }
}

export const requestFailedMyProfile = () => {
    return {
        type: MY_PROFILE_REQUEST_FAILED,
        payload: {},
    }
}
