import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Dropdown, DropdownMenu, DropdownToggle,} from "reactstrap";
import {Link, withRouter} from "react-router-dom";

//i18n
import {withTranslation} from "react-i18next";

// users
import {connect} from "react-redux";

class ProfileMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: false,
            name: '',
            profileImageUrl: '',
        }
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        const user = this.props.myProfile;
        this.setState({
            name: `${user.twitterName} (@${user.twitterUsername})`,
            profileImageUrl: user.twitterProfileImageUrl,
        })
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu,
        }))
    }

    render() {
        return (
            <React.Fragment>
                <Dropdown
                    isOpen={this.state.menu}
                    toggle={this.toggle}
                    className="d-inline-block"
                >
                    <DropdownToggle
                        className="btn header-item"
                        id="page-header-user-dropdown"
                        tag="button"
                    >
                        <img
                            className="rounded-circle header-profile-user"
                            src={this.state.profileImageUrl}
                            alt="Header Avatar"
                        />{" "}
                        <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <Link to="/logout" className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
                            <span>{this.props.t("Logout")}</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )
    }
}

ProfileMenu.propTypes = {
    t: PropTypes.any,

    myProfile: PropTypes.object,
}

const mapStateToProps = state => ({
    myProfile: state.MyProfile.myProfile,
})

export default withRouter(
    connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
