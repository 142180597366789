import {LOGIN_FAIL, LOGIN_PROCESSING, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS} from "./actionTypes"

const initialState = {
    isLoginFailed: false,
    isLoginProcessing: false,
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_FAIL:
            return {
                ...state,
                isLoginFailed: true,
                isLoginProcessing: false,
            }
        case LOGIN_PROCESSING:
            return {
                ...state,
                isLoginProcessing: true,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoginFailed: false,
                isLoginProcessing: false,
            }
        case LOGOUT_USER:
            return {
                ...state
            }
        case LOGOUT_USER_SUCCESS:
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}

export default login
