import React, {Component} from "react"
import PropTypes from "prop-types";

import "./approveListFilter.scss";

class ApproveListFilterButton extends Component {
    render() {
        return (
            <>
                <input type="checkbox" className="btn-check"
                       id={`list-filter-${this.props.filterKey}`} autoComplete="off"
                       checked={this.props.checked} onChange={e => this.props.onChange(e.target.checked)}
                />
                <label className={`btn btn-sm btn-outline-${this.props.filterColor}`}
                       htmlFor={`list-filter-${this.props.filterKey}`}>
                    {this.props.filterName}
                </label>
            </>
        )
    }
}

ApproveListFilterButton.propTypes = {
    filterKey: PropTypes.string,
    filterName: PropTypes.string,
    filterColor: PropTypes.string,

    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ApproveListFilterButton;
