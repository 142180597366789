import React, {Component} from "react"
import PropTypes from "prop-types";
import {Card, CardBody, CardTitle, Col, Container, Row,} from "reactstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {communityApproveMe} from "../../store/community/actions";
import StaffCard from "./StaffCard";

import rekuImg from "../../assets/images/staff-image/T_레쿠.PNG";
import horyuImg from "../../assets/images/staff-image/P_호류.png";
import kurangImg from "../../assets/images/staff-image/K_쿠랑님.png";
import nigiImg from "../../assets/images/staff-image/K_니기님.jpg";
import arinImg from "../../assets/images/staff-image/K_아린님.jpg";
import rudImg from "../../assets/images/staff-image/D_루들러님.jpg";
import doyongImg from "../../assets/images/staff-image/D_도룡뇽.png";
import yaongImg from "../../assets/images/staff-image/D_야옹님.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "./communityStaff.scss";

class CommunityStaff extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        document.title = '관리자 목록 / 레쿠 소통방';

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="레쿠 소통방" breadcrumbItem="관리자 목록"/>

                        <Row>
                            <Col sm={6}>
                                {/*총괄*/}
                                <StaffCard
                                    backgroundColor="#ffcbcb"
                                    image={rekuImg}
                                    name="레쿠"
                                    team="총관리자"
                                    socials={[
                                        {
                                            icon: 'twitter',
                                            id: 'reku_the_cat'
                                        },
                                        {
                                            icon: 'discord',
                                            id: 'Rk#9617'
                                        }
                                    ]}
                                />
                            </Col>
                            <Col sm={6}>
                                {/*기술지원팀*/}
                                <StaffCard
                                    backgroundColor="#bdfafa"
                                    image={horyuImg}
                                    name="호류"
                                    team="기술지원팀 팀장"
                                    socials={[
                                        {
                                            icon: 'discord',
                                            id: '호류#7777'
                                        }
                                    ]}
                                />
                            </Col>

                            <Col lg={6}>
                                {/*카카오톡 팀*/}
                                <Card>
                                    <CardBody style={{backgroundColor: '#fff6a7'}}>
                                        <CardTitle className="text-center">
                                            카카오톡 팀
                                        </CardTitle>

                                        <StaffCard
                                            image={kurangImg}
                                            name="쿠랑"
                                            team="처벌부 부장"
                                            socials={[
                                                {
                                                    icon: 'twitter',
                                                    id: 'Kranke_Mudd'
                                                },
                                                {
                                                    icon: 'discord',
                                                    id: '쿠랑케#1709'
                                                }
                                            ]}
                                        />
                                        <StaffCard
                                            image={nigiImg}
                                            name="니기"
                                            team="상담부 부장"
                                            socials={[
                                                {
                                                    icon: 'twitter',
                                                    id: 'Nick_jutopia'
                                                },
                                                {
                                                    icon: 'discord',
                                                    id: '여우별2#8783'
                                                }
                                            ]}
                                        />
                                        <StaffCard
                                            image={arinImg}
                                            name="아린"
                                            team="상담부"
                                            socials={[
                                                {
                                                    icon: 'twitter',
                                                    id: 'finiclove'
                                                },
                                                {
                                                    icon: 'discord',
                                                    id: '아린#6697'
                                                }
                                            ]}
                                        />
                                    </CardBody>
                                </Card>

                            </Col>
                            <Col lg={6}>
                                {/*디스코드 팀*/}
                                <Card>
                                    <CardBody style={{backgroundColor: '#c3c8ff'}}>
                                        <CardTitle className="text-center">
                                            디스코드 팀
                                        </CardTitle>

                                        <StaffCard
                                            image={rudImg}
                                            name="루들러"
                                            team="통합관리부 부장"
                                            socials={[
                                                {
                                                    icon: 'twitter',
                                                    id: 'RudlerD'
                                                },
                                                {
                                                    icon: 'discord',
                                                    id: 'Rudler#2821'
                                                }
                                            ]}
                                        />
                                        <StaffCard
                                            image={doyongImg}
                                            name="도룡뇽"
                                            team="통합관리부"
                                            socials={[
                                                {
                                                    icon: 'twitter',
                                                    id: 'dolyongnyong1'
                                                },
                                                {
                                                    icon: 'discord',
                                                    id: '도룡뇽#9399'
                                                }
                                            ]}
                                        />
                                        <StaffCard
                                            image={yaongImg}
                                            name="야옹"
                                            team="새벽관리부 부장"
                                            socials={[
                                                {
                                                    icon: 'twitter',
                                                    id: 'Gaho_23'
                                                },
                                                {
                                                    icon: 'discord',
                                                    id: 'Nag#7173'
                                                }
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

CommunityStaff.propTypes = {
    myProfile: PropTypes.object,
    myApprove: PropTypes.object,

    communityApproveMe: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    myProfile: state.MyProfile.myProfile,
    myApprove: state.Community.myApprove,
})

export default withRouter(
    connect(mapStateToProps, {communityApproveMe})(CommunityStaff)
);
