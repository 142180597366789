import React, {Component} from "react"
import PropTypes from "prop-types"
import {BrowserRouter as Router, Switch} from "react-router-dom"
import {connect} from "react-redux"

// Import Routes
import {authProtectedRoutes, publicRoutes} from "./routes/"
import AppRoute from "./routes/route"

// layouts
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/font.scss"

import store from "./store";
import {fetchMyProfile} from "./store/auth/myprofile/actions";
import SingleMessageNotice from "./pages/SystemNotice/SingleMessageNotice";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        if (!this.props.isMyProfileReceived) {
            store.dispatch(fetchMyProfile());
        }
    }

    render() {
        if (this.props.isMyProfileFetching)
            return <SingleMessageNotice title="로그인 정보를 확인하고 있어요." message="잠시만 기다려주세요."/>
        if (this.props.isMyProfileRequestFailed)
            return <SingleMessageNotice title="서버와 통신하지 못했어요." message="잠시 후 다시 시도해보시거나 증상이 계속되면 관리자에게 문의해주세요."/>

        if (!this.props.isMyProfileReceived) return null;
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={VerticalLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                                exact
                            />
                        ))}
                    </Switch>
                </Router>
            </React.Fragment>
        )
    }
}

App.propTypes = {
    isMyProfileFetching: PropTypes.bool.isRequired,
    isMyProfileReceived: PropTypes.bool.isRequired,
    isMyProfileRequestFailed: PropTypes.bool.isRequired,

    layout: PropTypes.object,
}

const mapStateToProps = state => ({
    isMyProfileFetching: state.MyProfile.isFetching,
    isMyProfileReceived: state.MyProfile.isReceived,
    isMyProfileRequestFailed: state.MyProfile.isRequestFailed,

    layout: state.Layout,
})

export default connect(mapStateToProps, null)(App)
