import {call, put, takeLatest} from "redux-saga/effects"

// Login Redux States
import {COMMUNITY_APPROVE_ME} from "./actionTypes"
import {communityApproveMeSuccess} from "./actions"

//Include Both Helper File with needed methods
import {getCommunityApprove} from "../../helpers/backend_helper";

function* approveMe() {
    const res = yield call(getCommunityApprove)
    yield put(communityApproveMeSuccess(res.data))
}

function* communitySaga() {
    yield takeLatest(COMMUNITY_APPROVE_ME, approveMe)
}

export default communitySaga
