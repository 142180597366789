import React, {Component} from "react"
import {Modal, ModalBody, ModalHeader, Table,} from "reactstrap";
import PropTypes from "prop-types";
import TwitterProfileLink from "../../../../components/TwitterProfileLink";
import {getAdminCommunityApproveLog} from "../../../../helpers/backend_helper";
import TimeText from "../../../../components/TimeText";

class ApproveLogModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approveList: [],
        };

        this.updateTable = this.updateTable.bind(this)
        this.getStatusName = this.getStatusName.bind(this)
        this.getStatusColor = this.getStatusColor.bind(this)
        this.getCommunityName = this.getCommunityName.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.approve) !== JSON.stringify(prevProps.approve)) {
            this.updateTable();
        }
    }

    updateTable() {
        getAdminCommunityApproveLog(this.props.approve.userId).then(res => {
            this.setState({approveList: res.data})
        })
    }

    getStatusName(status) {
        if (status === 'NEW_APPROVE') {
            return '심사 대기';
        } else if (status === 'ACCEPT') {
            return '입장 허가';
        } else if (status === 'REMOVE_ACCEPT') {
            return '입장 허가 취소';
        } else if (status === 'REJECT') {
            return '입장 거절';
        } else if (status === 'BLOCK') {
            return '입장 요청 차단';
        } else if (status === 'UNBLOCK') {
            return '입장 요청 차단 해제';
        }

        return '오류';
    }

    getStatusColor(status) {
        if (status === 'NEW_APPROVE') {
            return '';
        } else if (status === 'ACCEPT') {
            return 'table-success';
        } else if (status === 'REMOVE_ACCEPT') {
            return 'table-light';
        } else if (status === 'REJECT') {
            return 'table-warning';
        } else if (status === 'BLOCK') {
            return 'table-danger';
        } else if (status === 'UNBLOCK') {
            return 'table-light';
        }

        return '';
    }

    getCommunityName(community) {
        if (community === 'KAKAO_TALK') {
            return '카카오톡 (KakaoTalk)';
        } else if (community === 'DISCORD') {
            return '디스코드 (Discord)';
        }

        return '오류';
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                scrollable={true}
                backdrop={'static'}
                id="staticBackdrop"
                size="xl"
            >
                <ModalHeader toggle={this.props.onClose}>
                    [#{this.props.approve ? this.props.approve.id : ''}] 기록 보기
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <Table className="table table-hover mb-0">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>상태</th>
                                <th>트위터 닉네임</th>
                                <th>트위터 아이디</th>
                                <th>신청 커뮤니티</th>
                                <th>입장 관리팀에 전하는 말</th>
                                <th>처리자</th>
                                <th>처리 일자</th>
                                <th>사유/처리자 메모</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.approveList
                                    .map(approve =>
                                        <tr key={approve.id}
                                            className={this.getStatusColor(approve.status)}>
                                            <th scope="row">{approve.id}</th>
                                            <td>{this.getStatusName(approve.status)}</td>
                                            <td>{approve.twitterName}</td>
                                            <td>
                                                <TwitterProfileLink
                                                    twitterUsername={approve.twitterUsername}
                                                />
                                            </td>
                                            <td>{this.getCommunityName(approve.community)}</td>
                                            <td>{approve.contents}</td>
                                            <td>{approve.adminNickname}</td>
                                            <td>
                                                <TimeText time={approve.createdAt}/>
                                            </td>
                                            <td>{approve.reason}</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={this.props.onClose}>
                        닫기
                    </button>
                </div>
            </Modal>
        )
    }
}

ApproveLogModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    approve: PropTypes.object,

    onClose: PropTypes.func.isRequired,
}

export default ApproveLogModal;
