import React, {Component} from "react"
import {Col, Container, Row} from "reactstrap"

//Import Images
import logo from "../../assets/images/logo-dark.png"
import lightlogo from "../../assets/images/logo-light.png"

class SingleMessageNotice extends Component {
    render() {
        document.title = this.props.title + ' / 레쿠 소통방';

        return (
            <React.Fragment>
                <section className="my-5 pt-sm-5">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <div className="home-wrapper">
                                    <div className="mb-5">
                                        <div className="d-block auth-logo">
                                            <img src={logo} alt="logo" height="20" className="auth-logo-dark mx-auto"/>
                                            <img src={lightlogo} alt="logo" height="20"
                                                 className="auth-logo-light mx-auto"/>
                                        </div>
                                    </div>

                                    <h3 className="mt-5">{this.props.title}</h3>
                                    <p>{this.props.message}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

export default SingleMessageNotice
