import React, {Component} from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import {Alert, Col, Container, Row, UncontrolledAlert} from "reactstrap";

// Redux
import {connect} from "react-redux";
import {Link, Redirect, withRouter} from "react-router-dom";

// actions
import {loginFail, twitterLogin} from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handleClickTwitterLogin = this.handleClickTwitterLogin.bind(this)
    }

    componentDidMount() {
        const urlSearch = this.props.location.search;
        const urlQuery = queryString.parse(urlSearch);

        if (urlQuery.state && urlQuery.code) {
            const urlParams = {
                state: urlQuery.state,
                code: urlQuery.code,
            };

            this.props.twitterLogin(urlParams, this.props.history)
        } else if (urlQuery.error) {
            this.props.loginFail();
        }
    }

    handleClickTwitterLogin = () => {
        location.href = `${process.env.REACT_APP_API_URL}/oauth2/authorization/twitter`;
    }

    render() {
        document.title = '레쿠';

        if (this.props.isLoggedIn) {
            return (
                <Redirect
                    to={{pathname: "/community/approve", state: {from: this.props.location}}}
                />
            )
        }

        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <CarouselPage/>

                            <Col xl={3}>
                                <div className="auth-full-page-content p-md-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100">
                                            <div className="mb-4 mb-md-5">
                                                <Link to="dashboard" className="d-block auth-logo">
                                                    <img
                                                        src={logodark}
                                                        alt=""
                                                        height="30"
                                                        className="auth-logo-dark"
                                                    />
                                                    <img
                                                        src={logolight}
                                                        alt=""
                                                        height="30"
                                                        className="auth-logo-light"
                                                    />
                                                </Link>
                                            </div>

                                            <div className="my-auto">
                                                <div>
                                                    <h5 className="text-primary">안녕하세요!</h5>
                                                    <p className="text-muted">
                                                        서비스를 이용하기 위해서는 트위터 로그인이 필요해요.
                                                    </p>
                                                </div>

                                                <div className="mt-4">
                                                    <div className="mb-3">
                                                        {this.props.isLoginFailed && <>
                                                            <UncontrolledAlert color="danger" role="alert">
                                                                로그인에 실패했어요. 잠시 후 다시 시도해보시거나 증상이 계속되면 관리자에게 문의해주세요.
                                                            </UncontrolledAlert>
                                                        </>}

                                                        {this.props.isLoginProcessing && <>
                                                            <Alert color="warning" role="alert">
                                                                로그인 중이에요. 잠시만 기다려주세요.
                                                            </Alert>
                                                        </>}

                                                        {!this.props.isLoginProcessing && <>
                                                            <div className="btn-group" style={{width: '100%'}}>
                                                                <a className='btn btn-info disabled'
                                                                   style={{maxWidth: '40px'}}>
                                                                    <i className="fab fa-twitter"
                                                                       style={{width: '16px', height: '16px'}}/>
                                                                </a>

                                                                <a className="btn btn-info"
                                                                   onClick={this.handleClickTwitterLogin}>
                                                                    트위터 아이디로 로그인
                                                                </a>
                                                            </div>
                                                            <p style={{color: 'green', fontWeight: 'bold'}}>
                                                                로그인 시 요청하는 권한은 로그인한 사용자 정보를 확인하는 목적으로만 사용되며, 비공개 정보를 포함한 트윗과 계정 목록 등에 대해서는 로그인을 위한 최소한의 권한으로, 이를 수집 및 처리하지는 않아요.
                                                            </p>
                                                            <p style={{color: 'red'}}>
                                                                트위터 공식 앱의 버그로 인하여 모바일로는 로그인이 불가능할 수 있어요.
                                                            </p>
                                                        </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4 mt-md-5 text-center">
                                                <p className="mb-0">
                                                    Copyright {new Date().getFullYear()}. Horyu All rights reserved.
                                                </p>
                                                <p style={{color: 'red'}}>
                                                    그림의 저작권은 해당 그림의 저작권자에게 있으며, 저작권자의 허락없이 불법으로 복사/유포하는 행위를 엄격히 금지합니다.
                                                    이외에 사이트의 모든 저작권은 Horyu 에게 있습니다.
                                                </p>
                                                <a href="https://cs.reku.kr/privacy-policy"
                                                   target="_blank"
                                                   rel="noreferrer">개인정보 처리방침</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

Login.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    isLoggedIn: PropTypes.bool.isRequired,

    isLoginFailed: PropTypes.bool,
    isLoginProcessing: PropTypes.bool,

    twitterLogin: PropTypes.func,
    loginFail: PropTypes.func,
};

const mapStateToProps = state => ({
    isLoggedIn: state.MyProfile.isLoggedIn,

    isLoginFailed: state.Login.isLoginFailed,
    isLoginProcessing: state.Login.isLoginProcessing
});

export default withRouter(
    connect(mapStateToProps, {twitterLogin, loginFail})(Login)
);
