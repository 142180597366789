import {get, post, put} from "./api_helper"
import * as url from "./url_helper"


export const getTwitterLogin = params => get(url.GET_TWITTER_LOGIN, {params})
export const getMyProfile = () => get(url.GET_MY_PROFILE)
export const getLogout = () => get(url.GET_LOGOUT)

export const getCommunityApprove = () => get(url.GET_COMMUNITY_APPROVE)
export const putCommunityApprove = data => put(url.PUT_COMMUNITY_APPROVE, data)
export const getCommunityJoin = (community) => get(`${url.GET_COMMUNITY_JOIN}/${community}`)

export const getAdminCommunityApproveList = () => get(url.GET_ADMIN_COMMUNITY_APPROVE_LIST)
export const postAdminCommunityApproveAction = data => post(url.POST_ADMIN_COMMUNITY_APPROVE_ACTION, data)
export const getAdminCommunityApproveLog = (userId) => get(`${url.GET_ADMIN_COMMUNITY_APPROVE_LOG}/${userId}`)

export const getAdminUserList = data => get(url.GET_ADMIN_USER_LIST, data)
