import React, {Component} from "react"
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row,} from "reactstrap";
import TwitterProfileLink from "../../components/TwitterProfileLink";

class StaffCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card style={{backgroundColor: this.props.backgroundColor}}>
                <CardBody>
                    <div className="d-flex">
                        <div className="me-3">
                            <img
                                src={this.props.image}
                                alt="스탭 프로필사진"
                                className="avatar-lg rounded-circle img-thumbnail"
                                style={{
                                    padding: 0
                                }}
                            />
                        </div>
                        <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                                <h5 className="mb-0 staff-name">{this.props.name}</h5>
                                <p className="mb-1">{this.props.team}</p>
                                <div className="mb-0">
                                    <Row>
                                        {/* eslint-disable-next-line react/prop-types */}
                                        {this.props.socials && this.props.socials.map(social => (
                                            <Col sm={6} key={social.icon}>
                                                <i className={`bx bxl-${social.icon}`}
                                                   style={{verticalAlign: 'middle', marginRight: '5px'}}/>

                                                {social.icon === 'twitter' ? <>
                                                    <TwitterProfileLink
                                                        twitterUsername={social.id}
                                                    />
                                                </> : <span>{social.id}</span>}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

StaffCard.propTypes = {
    backgroundColor: PropTypes.string,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    team: PropTypes.string.isRequired,
    socials: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })),
}

export default StaffCard;
