import React, {Component} from "react"
import classnames from "classnames";
import {Badge, Card, CardBody, Col, Container, Row, Table,} from "reactstrap";
import {getAdminUserList} from "../../../helpers/backend_helper";
import TwitterProfileLink from "../../../components/TwitterProfileLink";
import TimeText from "../../../components/TimeText";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import './userList.scss';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
        };

        this.updateTable = this.updateTable.bind(this)
        this.authorityName = this.authorityName.bind(this)
    }

    componentDidMount() {
        this.updateTable();

        toastr.options = {
            positionClass: 'toast-top-full-width',
            timeOut: 5000,
            closeButton: true,
            progressBar: true,
        };
    }

    updateTable() {
        getAdminUserList().then(res => {
            this.setState({userList: res.data})
        })
    }

    authorityName(authority) {
        switch (authority) {
            case 'MANAGE_COMMUNITY_ACCESS':
                return '레쿠 소통방 입장 관리';
            case 'MANAGE_COMMUNITY_SETTING':
                return '레쿠 소통방 설정 관리';
            case 'MANAGE_USER':
                return '사용자 관리';
        }
    }

    render() {
        document.title = '사용자 관리 / 관리자';

        return (
            <React.Fragment>
                <div className="page-content user-list-page">
                    <Container fluid>
                        <Breadcrumbs title="관리자" breadcrumbItem="사용자 관리"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table table-sm table-hover mb-0">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>트위터</th>
                                                    <th>사이트 가입</th>
                                                    <th>사이트 마지막 로그인</th>
                                                    <th>권한</th>
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.userList
                                                        .map(user =>
                                                            <tr key={user.id}>
                                                                <th scope="row">{user.id}</th>
                                                                <td>
                                                                    {user.twitterName} (<TwitterProfileLink
                                                                    twitterUsername={user.twitterUsername}/>)
                                                                </td>
                                                                <td>
                                                                    <TimeText time={user.registeredAt}/>
                                                                </td>
                                                                <td>
                                                                    <TimeText time={user.lastLoginAt}/>
                                                                </td>
                                                                <td>
                                                                    {user.authorities.map((authority, idx) => (
                                                                        <Badge
                                                                            key={authority}
                                                                            color="primary"
                                                                            className={classnames('font-size-11', {'authority-spacing': idx > 0})}
                                                                        >
                                                                            {this.authorityName(authority)}
                                                                        </Badge>
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default UserList;
