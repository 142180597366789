import React from "react"
import PropTypes from 'prop-types'
import {Redirect, Route, withRouter} from "react-router-dom"
import {connect} from "react-redux";

const AppRoute = ({
                      isLoggedIn,
                      isAuthProtected,
                      component: Component,
                      layout: Layout,
                      ...rest
                  }) => (
    <Route
        {...rest}
        render={props => {
            if (isAuthProtected && !isLoggedIn) {
                return (
                    <Redirect
                        to={{pathname: "/login", state: {from: props.location}}}
                    />
                )
            }

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }}
    />
)

AppRoute.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,

    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any
}

const mapStateToProps = state => () => ({
    isLoggedIn: state.MyProfile.isLoggedIn,
})

export default withRouter(
    connect(mapStateToProps, {})(AppRoute)
)
