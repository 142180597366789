import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Community
import CommunityApprove from "../pages/CommunityApprove/CommunityApprove"
import CommunityStaff from "../pages/CommunityStaff/CommunityStaff"
import CommunityApproveList from "../pages/Admin/CommunityApproveList/ApproveList"

// Admin
import UserList from "../pages/Admin/UserList/UserList";

const authProtectedRoutes = [
  { path: "/community/approve", component: CommunityApprove },
  { path: "/community/staff", component: CommunityStaff },

  { path: "/admin/community/approve", component: CommunityApproveList },
  { path: "/admin/user", component: UserList },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/community/approve" /> }
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }
