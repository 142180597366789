import {all, fork} from "redux-saga/effects"

import AuthSaga from "./auth/login/saga"
import MyProfileSaga from "./auth/myprofile/saga"

//public
import LayoutSaga from "./layout/saga"
import CommunitySaga from "./community/saga"

export default function* rootSaga() {
    yield all([
        //public
        fork(AuthSaga),
        fork(MyProfileSaga),
        fork(LayoutSaga),
        fork(CommunitySaga),
    ])
}
