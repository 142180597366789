import {
    MY_PROFILE_FETCHING,
    MY_PROFILE_RECEIVED_AND_LOGGED_IN,
    MY_PROFILE_RECEIVED_AND_NOT_LOGGED_IN,
    MY_PROFILE_REQUEST_FAILED
} from "./actionTypes"

const initialState = {
    isFetching: false,
    isReceived: false,
    isRequestFailed: false,
    isLoggedIn: false,
    myProfile: null,
}

const myProfile = (state = initialState, action) => {
    switch (action.type) {
        case MY_PROFILE_FETCHING:
            return {
                ...state,
                isFetching: true,
                isReceived: false,
                isRequestFailed: false,
                isLoggedIn: false,
                myProfile: null,
            }
        case MY_PROFILE_RECEIVED_AND_LOGGED_IN:
            return {
                ...state,
                isFetching: false,
                isReceived: true,
                isRequestFailed: false,
                isLoggedIn: true,
                myProfile: action.payload,
            }
        case MY_PROFILE_RECEIVED_AND_NOT_LOGGED_IN:
            return {
                ...state,
                isFetching: false,
                isReceived: true,
                isRequestFailed: false,
                isLoggedIn: false,
                myProfile: null,
            }
        case MY_PROFILE_REQUEST_FAILED:
            return {
                ...state,
                isFetching: false,
                isReceived: false,
                isRequestFailed: true,
                isLoggedIn: false,
                myProfile: null,
            }
        default:
            return {
                ...state
            }
    }
}

export default myProfile
