import {
    LOGIN_FAIL,
    LOGIN_PROCESSING,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    TWITTER_LOGIN
} from "./actionTypes"

export const twitterLogin = (data, history) => {
    return {
        type: TWITTER_LOGIN,
        payload: {data, history},
    }
}

export const loginSuccess = () => {
    return {
        type: LOGIN_SUCCESS,
        payload: {},
    }
}

export const logoutUser = history => {
    return {
        type: LOGOUT_USER,
        payload: {history},
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {},
    }
}

export const loginFail = () => {
    return {
        type: LOGIN_FAIL,
        payload: {},
    }
}

export const loginProcessing = () => {
    return {
        type: LOGIN_PROCESSING,
        payload: {},
    }
}
