// LOGIN
export const GET_TWITTER_LOGIN = "/oauth2/code/twitter"
export const GET_MY_PROFILE = "/profile"
export const GET_LOGOUT = "/logout"

// COMMUNITY :: APPROVE
export const GET_COMMUNITY_APPROVE = "/community/approve"
export const PUT_COMMUNITY_APPROVE = "/community/approve"
export const GET_COMMUNITY_JOIN = "/community/join"

// ADMIN :: COMMUNITY :: APPROVE
export const GET_ADMIN_COMMUNITY_APPROVE_LIST = "/admin/community/approve"
export const POST_ADMIN_COMMUNITY_APPROVE_ACTION = "/admin/community/approve/action"
export const GET_ADMIN_COMMUNITY_APPROVE_LOG = "/admin/community/approve/log"

// ADMIN :: USER
export const GET_ADMIN_USER_LIST = "/admin/user"
